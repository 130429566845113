<template>
  <div class="col-12">
    <div v-show="Object.keys(orderData).length > 0">
      <div class="row pl-3 pr-3 mt-3">
        <div class="col-6" style="line-height: 2;">
          <div class=""><b>Заказ №{{ orderData.id }}</b></div>
        </div>
        <div class="col-6 text-right">
          <div class="btn-group">
            <b-dropdown 
              size="sm" 
              :variant="buttonStatusColor(orderData.status_id)" 
              
            >                      
              <template v-slot:button-content>
                {{orderData.status_name}}
                <i class="mdi mdi-chevron-down"></i>
              </template>
                <b-dropdown-item 
                  href="javascript: void(0);" 
                  v-for="status in orderData.possible_statuses" 
                  :key="status.id"
                  @click="setStatus(orderData.id, status.id, +orderData.delivery)"
                >
                  {{status.name}}
                </b-dropdown-item>
              
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="row pl-3 pr-3 mt-3">
        <div class="col-6 font-13">
          {{ dateTimeToShort(orderData.created_at) }}
        </div>
        <div class="col-6 text-right font-15">
          <b>Итоговая сумма: {{ computedEndSum }} р.</b>
        </div>
      </div>
      <div class="row pl-3 pr-3 mt-3">
        <div class="col-12">
          <div v-if="+orderData.delivery === 1">
            <b>Заказ с доставкой по адресу: </b> {{ orderData.delivery_address }}
          </div>
          <div v-if="+orderData.delivery === 0">
            <b>Заказ в пункт выдачи: </b> {{ orderData.point_name }} ({{ orderData.point_address }})
          </div>
        </div>
      </div>
      <div class="row pl-3 pr-3 mt-3">
        <div class="col-12">
          <b>Тип заказа: </b> {{ orderData.service_name }}
          <span v-if="orderData.service_id == 3">
            ({{ orderData.type_rus }})
          </span>
        </div>
      </div>
      <div class="row pl-3 pr-3 mt-3">
        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
              <tr>
                <th v-for="(item, key) in dynamicFields[orderData.service_id]" :key="key">
                  {{ Object.values(item)[0] }}
                </th>
                <th>Клиент</th>
                <th>Телефон</th>
                <th v-if="userPermissions.includes('refund')">Возврат</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="(item, key) in dynamicFields[orderData.service_id]" :key="key">
                  {{ orderData[Object.keys(item)]  }}
                </td>
                <td>{{ orderData.user_id }}</td>
                <td>{{ orderData.user_number }}</td>
                <td v-if="userPermissions.includes('refund')">
                  <div @dblclick="refundOrder(orderData.id)" class="btn btn-sm">
                    <i class="ri-refund-2-line"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row pl-3 pr-3 mt-3">
        <div v-show="userPermissions.includes('show-orders-file')">
          <div class="col-lg-12">
            <div class="font-15">Список файлов: </div>
            <div class="mt-2">
              <div @click="getArchiveLink(orderData.id)" class="btn btn-sm btn-outline-info">Сформировать архив</div>
              <div 
                v-if="orderData.service_id == 3"
                @click="getDocumentPhotoGrid(orderData.id)" 
                class="btn btn-sm btn-outline-info ml-1"
              >
                Сформировать сетку
              </div>
            </div>
            <div class="mt-2 files-container">
              <div v-for="(item, index) in orderData.files" :key="index">
                <div class="text-muted" v-if="+item.exists === 0">{{item.name.slice(0, 20)}}</div>
                <a v-if="+item.exists === 1" :href="item.file_path" target="_blank">
                  {{ item.name.slice(0, 20) }} ({{ item.copies }} копий)
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="" v-show="Object.keys(orderData).length === 0">
      <div class="col-12">
        <div class="alert alert-sm alert-danger">Заказ не найден</div>
      </div>
    </div>


    <popup
      :closeButton="archiveFilesPopup.closeButton"
      :show="archiveFilesPopup.show"
      :size="'lg'"
      @closePopup="closeArchiveFilesPopup"
    >
      <div slot="header">Выгрузить файлы заказа №{{ achiveOrderId }}</div>
      <div slot="body">
        <div class="alert alert-sm alert-success">
          <div>Архив сформирован. Скачайте его по ссылке:</div>
          <div>
            <a :href="archiveLink" target="_blank">{{ archiveLink.slice(0, 40) }}...</a>
          </div>
        </div>
      </div>
    </popup>


    <popup
      :closeButton="documentPhotoGridPopup.closeButton"
      :show="documentPhotoGridPopup.show"
      :size="'lg'"
      @closePopup="closeDocumentPhotoGridPopup"
    >
      <div slot="header">Выгрузить PDF заказа №{{ documentPhotoGridOrderId }}</div>
      <div slot="body">
        <div class="alert alert-sm alert-success">
          <div>PDF сформирован. Скачайте его по ссылке:</div>
          <div>
            <a :href="documentPhotoGridLink" target="_blank">{{ documentPhotoGridLink.slice(0, 40) }}...</a>
          </div>
        </div>
      </div>
    </popup>


    <popup
      :closeButton="pinPopup.closeButton"
      :actionButton="pinPopup.actionButton"
      :actionClass="pinPopup.actionClass"
      :show="pinPopup.show"
      @closePopup="closePinPopup"
      @actionPopup="pinPopupSubmit"
    >
      <div slot="header">{{ $t('views.orders.confirm_order') }}</div>
      <div slot="body">
        <div class="text-center">
          {{ $t('views.orders.confirm_order_text') }}
        </div>
        <div class="mt-3">
          <div class="form-group">
            <input
                id="pin"
                v-model="pin"
                type="text"
                class="form-control form-control-sm"
            />
          </div>
        </div>
      </div>
    </popup>

  </div>
</template>

<script>



import axios from "axios";
//import SimpleSelectCheckbox from "@/components/SimpleSelectCheckbox";
import Formatter from "../../libraries/Formatter";
import { mapState } from "vuex";
import Popup from '../../Popup.vue';

const formatter = new Formatter();

export default {
  name: "CardOrderService",
  components: {Popup},
  data() {
    return {
      archiveFilesPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      achiveOrderId: '',
      archiveLink: '-',
      showFiles: false,

      documentPhotoGridPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      documentPhotoGridOrderId: '',
      documentPhotoGridLink: '',

      setStatusForce: false,
      pinPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      setStatusId: 0,
      setStatusOrderId: 0,
      pin: '',

      dynamicFields: {
        1: [
          {paper: "Бумага"},
          {format: "Формат"},
          {borders: "С полями"},
        ]
      }
    }
  },
  props: {
    orderData: Object
  },
  watch: {
    
  },
  computed: {
    ...mapState({
      userPermissions: state => state.authorization.userPerm,
    }),
    computedEndSum(){
      let result = +this.orderData.price;
      if(+this.orderData.delivery === 1){
        result += +this.orderData.delivery_price;
      }
      if(+this.orderData.use_point === 1){
        result += +this.orderData.price_point;
      }
      return result;
    }
  },
  validations: {
    
  },
  methods: {
    closeDocumentPhotoGridPopup(){
      this.documentPhotoGridPopup.show = false;
    },
    getDocumentPhotoGrid(orderId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      
      axios
        .post(`/v1/grid/document-photo`, formData)
        .then(resp => {
          this.documentPhotoGridLink = resp.data.download_link;
          this.documentPhotoGridOrderId = orderId;
          this.documentPhotoGridPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    getArchiveLink(orderId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      
      axios
        .post(`/v1/orders/get-service-archive`, formData)
        .then(resp => {
          this.archiveLink = resp.data.archive_path;
          this.achiveOrderId = orderId;
          this.archiveFilesPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    closeArchiveFilesPopup(){
      this.archiveFilesPopup.show = false;
    },
    refundOrder(orderId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("type", 'service');
      
      axios
        .post(`/v1/orders/refund`, formData)
        .then(resp => {
          if(resp.data.result == 'ok'){
            this.$store.dispatch("addNotification", {
              text: resp.data.message,
              time: 3,
              color: "success"
            });
            this.$emit('updateList');
          }else{
            this.$store.dispatch("addNotification", {
              text: resp.data.message,
              time: 8,
              color: "danger"
            });
          }
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    closePinPopup(){
      this.pinPopup.show = false;
      this.pin = '';
    },
    showPinPopup(){
      this.pinPopup.show = true;
    },
    pinPopupSubmit(){
      this.setStatusForce = true;
      this.setStatus(
        this.setStatusOrderId, 
        this.setStatusId,
        0,
        'done'
      );
    },
    setStatus(orderId, statusKey, isDelivery){
      if(
        statusKey === 'done' &&
        isDelivery === 0 && 
        !this.setStatusForce && 
        !this.userPermissions.includes('completed-orders-force')
      ){
        this.setStatusOrderId = orderId;
        this.setStatusId = statusKey;
        this.showPinPopup();
        return false;
      }
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("status_id", statusKey);
      formData.append("type", 'service');
      formData.append("pin", this.pin);

      axios
        .post(`/v1/orders/set-status`, formData)
        .then(() => {
          this.$emit('updateOrder');
          this.setStatusForce = false;
          this.closePinPopup();
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    dateTimeToShort(dt){
      return formatter.phpDateTimeToShortString(dt);
    },
    toggleShowFiles(){
      this.showFiles = !this.showFiles;
    },
    buttonStatusColor(statusId){
      if(+statusId === 1){
        return 'danger';
      }else if(+statusId === 2){
        return 'warning';
      }else if(+statusId === 3){
        return 'info';
      }else if(+statusId === 4){
        return 'success';
      }else if(+statusId === 5){
        return 'info';
      }else if(+statusId === 6){
        return 'info';
      }else if(+statusId === 7){
        return 'info';
      }
    },
  },
  created() {
    
  }

};
</script>

<style scoped lang="scss">
.font-15{
  font-size: 15px;
}
.font-13{
  font-size: 13px;
}
.files-container{
  &>div{
    display: inline-block;
    margin-right: 15px;
    margin-top: 10px;
  }
}
</style>

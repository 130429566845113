<template>
  <div>
    <div class="row">
      <div @click="toggleShowMore" class="col-lg-9 cursor-pointer">
        <div class="row">
          <div class="col-lg-2 font-15">
            <b>Заказ №{{ orderData.id }}</b>
          </div>
          <div class="col-lg-10 font-15">
            <div v-if="+orderData.delivery === 1">
              <b>Заказ с доставкой по адресу: </b> {{ orderData.delivery_address }}
            </div>
            <div v-if="+orderData.delivery === 0">
              <b>Заказ самообслуживание: </b> {{ orderData.printer_name }} ({{ orderData.printer_address }})
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-2 font-13">
            {{ dateTimeToShort(orderData.created_at) }}
          </div>
          <div class="col-lg-10 font-15">
            <b>Тип заказа: </b> Печать документов
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="row">
          <div class="col-lg-12 text-right">
            <div class="btn-group">
              <b-dropdown
                  size="sm"
                  :variant="buttonStatusColor(orderData.status_key)"
              >
                <template v-slot:button-content>
                  {{getStatusName(orderData.status_key)}}
                  <i class="mdi mdi-chevron-down"></i>
                </template>
                <b-dropdown-item
                    href="javascript: void(0);"
                    v-for="status in statuses()"
                    :key="status"
                    @click="setStatus(orderData.id, status)"
                >
                  {{getStatusName(status)}}
                </b-dropdown-item>

              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="row mt-2 text-right font-15">
          <div class="col-lg-12" title="Стоимость заказа (с вычетом бонусов) + доставка">
            Итоговая сумма: {{ computedEndSum }} р.
          </div>
          <div class="col-lg-12 small" style="opacity: 0.5" v-if="orderData.price_point > 0">
            ({{ Math.floor(orderData.price_point) }} р. оплачено баллами)
          </div>
        </div>
      </div>
    </div>

    <div v-show="showMore" class="row mt-4">
      <div class="col-lg-12">
        <table class="table table-hover" style="cursor: pointer;">
          <thead>
          <tr>
            <th>Цвет</th>
            <th>Формат</th>
            <th>Двусторон.</th>
            <th>Копий</th>
            <th>Клиент</th>
            <th>Телефон</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <i v-if="+orderData.color === 1" class="ri-checkbox-circle-fill text-success font-size-18"></i>
              <i v-if="+orderData.color === 0" class="ri-close-circle-fill text-danger font-size-18"></i>
            </td>
            <td>{{ orderData.format }}</td>
            <td>
              <i v-if="+orderData.duplex === 1" class="ri-checkbox-circle-fill text-success font-size-18"></i>
              <i v-if="+orderData.duplex === 0" class="ri-close-circle-fill text-danger font-size-18"></i>
            </td>
            <td>{{ orderData.copies }}</td>
            <td>{{ orderData.user_id }}</td>
            <td>{{ orderData.user_number }}</td>
            <td style="text-align: right;">
              <div v-if="userPermissions.includes('show-orders-file')" @click="toggleShowFiles" class="btn btn-sm btn-light mr-2">Скачать файлы</div>
              <div @click="showLogOrderPopup(orderData.id)" class="btn btn-sm btn-light mr-2">Открыть логи</div>
              <div v-if="userPermissions.includes('refund')" @click="refundOrder(orderData.id)" class="btn btn-sm btn-light mr-2">Возврат средств</div>
              <div @click="printOrder(orderData.id)" class="btn btn-sm btn-light">Запустить печать</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-show="showFiles && showMore" class="row mt-3">
      <div class="col-lg-12">
        <div class="font-15">Список файлов: </div>
        <div class="download-container mt-3">
          <div class="convert">
            <div class="item mb-2">{{$t('views.orders.afterConvert')}}</div>
            <div class="item" v-for="(convert, index) in orderData.files.convert" :key="index">
              <div class="text-muted" v-if="+convert.exists === 0">{{convert.name.slice(0, 20)}}</div>
              <a v-if="+convert.exists === 1" :href="convert.file_path" target="_blank">
                {{convert.name.slice(0, 20)}}
                <i class="ri-download-fill"></i>
              </a>
            </div>
            <div class="item mt-3">
              <div @click="getArchiveLink(orderData.id, 'convert')" class="btn btn-sm btn-outline-info">Сформировать архив</div>
            </div>
          </div>
          <div class="original">
            <div class="item mb-2">{{$t('views.orders.original')}}</div>
            <div class="item" v-for="(original, index) in orderData.files.original" :key="index">
              <div class="text-muted" v-if="+original.exists === 0">{{original.name.slice(0, 20)}}</div>
              <a v-if="+original.exists === 1" :href="original.file_path" target="_blank">
                {{original.name.slice(0, 20)}}
                <i class="ri-download-fill"></i>
              </a>
            </div>
            <div class="item mt-3">
              <div @click="getArchiveLink(orderData.id, 'original')" class="btn btn-sm btn-outline-info">Сформировать архив</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <popup
      :closeButton="logOrderPopup.closeButton"
      :show="logOrderPopup.show"
      :size="'lg'"
      @closePopup="closeLogOrderPopup"
    >
      <div slot="header">{{$t('views.orders.logOrder')}} #{{openOrderLog.orderId}}</div>
      <div slot="body">
        <div class="row">
          <div v-if="openOrderLog.log.length > 0" class="col-12 pl-0">
            <table class="table" style="cursor: pointer;">
              <thead>
                <tr>
                  <th>{{$t('views.orders.actions')}}</th>
                  <th>{{$t('views.orders.details')}}</th>
                  <th>{{$t('views.orders.datetime')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in openOrderLog.log" :key="item.id">
                  <td>{{item.history_action}}</td>
                  <td>{{item.history_description}}</td>
                  <td>{{item.history_datetime}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="openOrderLog.log.length === 0" class="col-12 pl-0">
            <div class="alert alert-sm alert-info">{{$t('views.orders.noEntries')}}</div>
          </div>
        </div>

      </div>
    </popup>


    <popup
      :closeButton="archiveFilesPopup.closeButton"
      :show="archiveFilesPopup.show"
      :size="'lg'"
      @closePopup="closeArchiveFilesPopup"
    >
      <div slot="header">Выгрузить файлы заказа №{{ achiveOrderId }}</div>
      <div slot="body">
        <div class="alert alert-sm alert-success">
          <div>Архив сформирован. Скачайте его по ссылке:</div>
          <div>
            <a :href="archiveLink" target="_blank">{{ archiveLink.slice(0, 40) }}...</a>
          </div>
        </div>
      </div>
    </popup>


  </div>
</template>

<script>
import axios from "axios";
import Popup from '../../../components/Popup.vue';
import Formatter from "../../libraries/Formatter";
import { mapState } from "vuex";
import {getStatusName, ORDER_STATUSES, showConfirmDialog} from "@/helpers/utils";

const formatter = new Formatter();

export default {
  name: "OrderPrinter",
  components: {Popup},
  data(){
    return {
      showMore: false,
      showFiles: false,
      logOrderPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },

      archiveFilesPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      achiveOrderId: '',
      archiveLink: '-',

      openOrderLog: {
        orderId: 0,
        log: []
      }
    }
  },
  props: {
    orderData: Object
  },
  watch: {

  },
  computed: {
    ...mapState({
      userPermissions: state => state.authorization.userPerm,
    }),
    computedEndSum(){
      let result = +this.orderData.price;
      if(+this.orderData.use_point === 1){
        result -= +this.orderData.price_point;
      }
      if(+this.orderData.delivery === 1){
        result += +this.orderData.delivery_price;
      }
      return result;
    }
  },
  validations: {

  },
  methods: {
    getStatusName,
    statuses() {
      return ORDER_STATUSES;
    },
    getArchiveLink(orderId, type){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("type", type);

      axios
        .post(`/v1/orders/get-printer-archive`, formData)
        .then(resp => {
          this.archiveLink = resp.data.archive_path;
          this.achiveOrderId = orderId;
          this.archiveFilesPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    closeArchiveFilesPopup(){
      this.archiveFilesPopup.show = false;
    },
    refundOrder(orderId){
      showConfirmDialog(`Вы действительно хотите сделать возврат средств по заказу №${orderId}?`, { ok: 'Вернуть средства' })
          .then(() => {
            this.$store.dispatch("startPreloader");
            let formData = new FormData();
            formData.append("order_id", orderId);
            formData.append("type", 'printer');

            axios
                .post(`/v1/orders/refund`, formData)
                .then(resp => {
                  if(resp.data.result === 'ok'){
                    this.$store.dispatch("addNotification", {
                      text: resp.data.message,
                      time: 3,
                      color: "success"
                    });
                    this.$emit('updateList');
                  }else{
                    this.$store.dispatch("addNotification", {
                      text: resp.data.message,
                      time: 8,
                      color: "danger"
                    });
                  }
                  this.$store.dispatch("stopPreloader");
                })
                .catch(err => {
                  this.downloadBlock = false;
                  this.$store.dispatch("addNotification", {
                    text: err.response.data.message,
                    time: 6,
                    color: "danger"
                  });
                  this.$store.dispatch("stopPreloader");
                });
          })
    },
    setStatus(orderId, statusKey){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("status_key", statusKey);
      formData.append("type", 'printer');

      axios
        .post(`/v1/orders/set-status`, formData)
        .then(() => {
          this.$emit('updateList');
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    dateTimeToShort(dt){
      return formatter.phpDateTimeToShortString(dt);
    },
    toggleShowMore(){
      this.showMore = !this.showMore;
    },
    toggleShowFiles(){
      this.showFiles = !this.showFiles;
    },
    buttonStatusColor(statusKey){
      if (statusKey === 'new') { return 'info'; }
      if (statusKey === 'paid') { return 'warning'; }
      if (statusKey === 'processing') { return 'warning'; }
      if (statusKey === 'in_delivery') { return 'warning'; }
      if (statusKey === 'ready_for_pickup') { return 'warning'; }
      if (statusKey === 'done') { return 'success'; }
      if (statusKey === 'failed') { return 'danger'; }
      if (statusKey === 'refunded') { return 'danger'; }
      if (statusKey === 'refund_failed') { return 'danger'; }
    },
    closeLogOrderPopup(){
      this.logOrderPopup.show = false;
    },
    showLogOrderPopup(order_id){
      this.openOrderLog.orderId = order_id;
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", order_id);

      axios
        .post(`/v1/orders/get-log`, formData)
        .then(resp => {
          this.openOrderLog.log = resp.data;
          this.logOrderPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    printOrder(orderId) {
      showConfirmDialog(`Запустить печать заказа №${orderId}?`, { ok: 'Запустить' })
          .then(() => {
            this.$store.dispatch("startPreloader");

            let formData = new FormData();
            formData.append("order_id", orderId);
            axios
                .post(`/v1/orders/print`, formData)
                .then(response => {
                  if (response.data.result === 'ok'){
                    this.$store.dispatch("addNotification", {
                      text: response.data.message,
                      time: 3,
                      color: "success"
                    });
                    this.$emit('updateList');
                  } else {
                    this.$store.dispatch("addNotification", {
                      text: response.data.message,
                      time: 8,
                      color: "danger"
                    });
                  }
                })
                .catch(error => {
                  this.downloadBlock = false;
                  this.$store.dispatch("addNotification", {
                    text: error.response.data.message,
                    time: 6,
                    color: "danger"
                  });
                })
                .finally(() => {
                  this.$store.dispatch("stopPreloader");
                });
          })
    },
  },
  created() {
  }
};
</script>

<style scoped lang="scss">
.download-container{
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-direction: row;
  font-size: 12px;
  .item{
    overflow-wrap: normal;  /* не поддерживает IE, Firefox; является копией word-wrap */
    word-wrap: normal;
    word-break: normal;  /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */
    line-break: auto;  /* нет поддержки для русского языка */
    hyphens: manual;  /* значение auto не поддерживается Chrome */
  }
  .convert{
    width: 200px;
    border-right: 1px solid #EFF2F7;
  }
  .original{
    width: 200px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
</style>

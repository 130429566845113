<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-4 position-relative">
            <i class="pt-1 pl-1 ri-search-line position-absolute z-1"></i>
            <input v-model="searchString" type="text" class="form-control pl-4 rounded-pill border-0 form-control-sm"
              :placeholder="$t('views.orders.searchStr')">
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-9">
        <div>
          <h4 class="card-title">Фильтры</h4>
          <div class="row mt-3">
            <div class="col-lg-3">
              <div class="form-group">
                <label for="">Дата</label>
                <date-picker v-model="daterange" range append-to-body :lang="pickerLang" :format="'DD MMM YYYY'"
                  :disabled-date="(date) => date > new Date()" :type="'date'" :max='new Date()' confirm class="ml-0 pl-0 mr-0  form-control-sm">
                </date-picker>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label for="">Тип услуги</label>
                <select v-model="filterType" class="form-control form-control-sm">
                  <option value="">-</option>
                  <option
                    v-for="item in service_types"
                    :key="item.id"
                    :value="item.codename"
                  >{{ item.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label for="">Статус</label>
                <select v-model="filterStatus" class="form-control form-control-sm">
                  <option value="">-</option>
                  <option
                    v-for="status in statuses()"
                    :key="status"
                    :value="status"
                  >{{ getStatusName(status) }}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="">Офис</label>
                <multiselect
                  v-model="officeSearch"
                  :options="options"
                  :custom-label="nameWithAddress"
                  :searchable="true"
                  :internal-search="false"
                  :preserve-search="false"
                  :loading="isLoading"
                  :selectLabel="'Выбрать'"
                  :deselectLabel="'Отменить'"
                  :selectedLabel="'Выбрано'"
                  @search-change="officeFind"
                  @select="selectOffice"
                  @remove="resetSelectOffice"
                  placeholder="Поиск по названию/адресу"
                >
                  <span slot="noResult">Ничего не найдено</span>
                  <span slot="noOptions">Список пуст, начните вводить данные для поиска</span>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 text-right clear-filters">
        <div @click="clearAllFilter" class="btn btn-sm btn-secondary">Сбросить фильтры</div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12">
        <template v-if="orders && orders.length > 0">
          <div v-for="(item, index) in orders" :key="index" class="card">
            <div class="card-body">
              <div v-if="item.order_type == 'printer'">
                <order-printer :orderData="item" @updateList="loadList"></order-printer>
              </div>
              <div v-if="item.order_type == 'service'">
                <order-service :orderData="item" @updateList="loadList"></order-service>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          Не найдено заказов по выбранным критериям
        </template>
      </div>
    </div>


    <div class="paginator mt-3 mb-3">
      <paginator
          :pages="pagesCountPaginator"
          :now="thisPage"
          @setPage="setPage"
      ></paginator>
    </div>

    <popup
      :closeButton="orderCardPopup.closeButton"
      :show="orderCardPopup.show"
      :size="'lg'"
      @closePopup="closeOrderCardPopup"
    >
      <div slot="header">
        {{$t('views.orders.order')}}
        <span v-if="Object.keys(this.queryParams).length > 0">№ {{ queryParams.id }}</span>
      </div>
      <div slot="body">
        <div class="row">
          <card-order-service
            :orderData="computedCardOrder"
            @updateOrder="updateOrderCard"
          ></card-order-service>
        </div>
      </div>
    </popup>

    <popup
      :closeButton="logOrderPopup.closeButton"
      :show="logOrderPopup.show"
      :size="'lg'"
      @closePopup="closeLogOrderPopup"
    >
      <div slot="header">{{$t('views.orders.logOrder')}} #{{openOrderLog.orderId}}</div>
      <div slot="body">
        <div class="row">
          <div v-if="openOrderLog.log.length > 0" class="col-12 pl-0">
            <table class="table" style="cursor: pointer;">
              <thead>
                <tr>
                  <th>{{$t('views.orders.actions')}}</th>
                  <th>{{$t('views.orders.details')}}</th>
                  <th>{{$t('views.orders.datetime')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in openOrderLog.log" :key="item.id">
                  <td>{{item.history_action}}</td>
                  <td>{{item.history_description}}</td>
                  <td>{{item.history_datetime}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="openOrderLog.log.length === 0" class="col-12 pl-0">
            <div class="alert alert-sm alert-info">{{$t('views.orders.noEntries')}}</div>
          </div>
        </div>
      </div>
    </popup>

  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import Formatter from '../../../components/libraries/Formatter';

const formatter = new Formatter();

import axios from "axios";
import Popup from '../../../components/Popup.vue';
import { mapState } from "vuex";
import OrderPrinter from '../../../components/management/orders/OrderPrinter.vue';
import OrderService from '../../../components/management/orders/OrderService.vue';
import Paginator from '../../../components/Paginator.vue';
import CardOrderService from '../../../components/management/orders/CardOrderService.vue';
import {getStatusName, ORDER_STATUSES} from "@/helpers/utils";

/**
 * Printers component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    Multiselect,
    //Paginator,
    Popup,
    OrderPrinter,
    OrderService,
    Paginator,
    CardOrderService
},
  data() {
    return {
      queryParams: this.$route.query,
      title: this.$t('views.orders.ordersAnalytics'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.orders'),
          active: true
        }
      ],
      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
           months: this.$t('datepicker.months'),
          weekdaysMin: this.$t('datepicker.weekdaysMin'),
          monthsShort: this.$t('datepicker.monthsShort'),
        },
        monthBeforeYear: false,
      },
      daterange: [],
      dateConvert: [],
      filterType: '',
      filterStatus: '',
      officeSearch: null,
      blockFilterWatch: false,
      searchStrTimeout: false,
      downloadBlock: false,
      ignoreDate: false,
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
      orders: [],
      logOrderPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      orderCardPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      openOrderLog: {
        orderId: 0,
        log: []
      },

      service_types: [],
      options: [],
      searchString: "",
      isLoading: false,

      cardOrder: {}
    };
  },
  validations: {

  },
  watch: {
    daterange: function (val) {
      let result = [];
      if(val[0] === null){
        this.dateConvert = [];
        return true;
      }
      if (val.length) {
        result[0] = formatter.jsObjectToPhp(val[0]);
        result[1] = formatter.jsObjectToPhp(val[1]);
      }
      this.dateConvert = result;
    },
    dateConvert: function () {
      if(!this.blockFilterWatch){
        this.setPage(1); // reset page on apply filters
        this.loadList();
      }
    },
    filterType: function () {
      if(!this.blockFilterWatch){
        this.setPage(1); // reset page on apply filters
        this.loadList();
      }
    },
    filterStatus: function () {
      if(!this.blockFilterWatch){
        this.setPage(1); // reset page on apply filters
        this.loadList();
      }
    },
    officeSearch: function () {
      if(!this.blockFilterWatch){
        this.setPage(1); // reset page on apply filters
        this.loadList();
      }
    },
    searchString: function() {
      clearTimeout(this.searchStrTimeout);
      if(!this.blockFilterWatch){
        this.searchStrTimeout = setTimeout(() => {
          this.setPage(1); // reset page on apply filters
          this.loadList();
        }, 2000);
      }
    },
  },
  computed: {
    ...mapState({
      userPermissions: state => state.authorization.userPerm,
    }),
    computedCardOrder() {
      return Array.isArray(this.cardOrder) ? {} : this.cardOrder;
    }
  },
  methods: {
    statuses() {
      return ORDER_STATUSES;
    },
    getStatusName,
    closeOrderCardPopup() {
      this.orderCardPopup.show = false;
    },
    showOrderCardPopup() {
      this.orderCardPopup.show = true;
      this.getCardOrder(this.queryParams.type, this.queryParams.id);
    },
    updateOrderCard() {
      this.getCardOrder(this.queryParams.type, this.queryParams.id);
      this.loadList();
    },
    getCardOrder (type, orderId) {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("type", type);
      formData.append("id", orderId);
      axios
        .post(`/v1/orders/get-one`, formData)
        .then(resp => {
          this.cardOrder = resp.data;
          this.$store.dispatch("startPreloader");
        })
        .catch(err => {
          //this.isLoading = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("startPreloader");
        });
    },
    nameWithAddress({ name, address }) {
      let text = `${name} - ${address}`;
      return text;
    },
    async clearAllFilter(){
      this.blockFilterWatch = true;
      this.$set(this.daterange, 0, null);
      this.$set(this.daterange, 1, null);
      this.dateConvert = [];
      this.filterType = '';
      this.filterStatus = '';
      this.officeSearch = null;
      this.searchString = '';
      await this.loadList().then(() => {
        this.blockFilterWatch = false;
      });
    },

    officeFind(query) {
      if (query.length) {
        // this.searchString = query;
        this.isLoading = true;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          let formData = new FormData();
          formData.append("query", query);
          axios
            .post(`/v1/orders/search-office`, formData)
            .then(resp => {
              this.options = resp.data;
              this.isLoading = false;
            })
            .catch(err => {
              this.isLoading = false;
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
        }, 800);
      }
    },
    selectOffice(val) {
      this.options = [];
      this.options.push(val);
      this.officeSearch = val;
    },
    resetSelectOffice() {
      this.officeSearch = null;
    },
    closeLogOrderPopup(){
      this.logOrderPopup.show = false;
    },
    setPage(val) {
      this.thisPage = val;
      this.loadList();
    },
    async loadList(){
      this.downloadBlock = true;
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      if(this.dateConvert.length){
        formData.append("filter[date_from]", this.dateConvert[0]);
        formData.append("filter[date_to]", this.dateConvert[1]);
      }
      if(this.filterType !== ''){
        formData.append("filter[type]", this.filterType);
      }
      if(this.filterStatus !== ''){
        formData.append("filter[status]", this.filterStatus);
      }
      if(this.officeSearch){
        formData.append("filter[office_type]", this.officeSearch.type);

        if(this.officeSearch.type === 'printer'){
          formData.append("filter[office_id]", this.officeSearch.serial_number);
        }else{
          formData.append("filter[office_id]", this.officeSearch.id);
        }
      }
      if(this.searchString !== ''){
        formData.append("filter[search_str]", this.searchString);
      }

      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);

      await axios
        .post(`/v1/orders/get-list`, formData)
        .then(resp => {
          this.downloadBlock = false;
          this.pagesCountPaginator = resp.data.pages;
          this.orders = resp.data.data;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    getServiceTypes(){
      axios
        .get(`/v1/orders/get-service-types`)
        .then(resp => {
          this.service_types = resp.data;
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    }
  },
  created() {
    this.getServiceTypes();
    this.loadList();

    if (Object.keys(this.queryParams).length > 0) {
      this.showOrderCardPopup();
    }
  }
};
</script>

<style scoped lang="scss">
.clear-filters{
  padding-top: 63px;
}
</style>
